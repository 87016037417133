import { gql } from '@apollo/client'

export const CUSTOMERS = gql`
query getCustomers($limit: Int!, $offset: Int!, $storeId: ID $searchQuery: String, $period: PeriodFilter) {
    customers(limit: $limit, offset: $offset, storeId: $storeId searchQuery: $searchQuery, period: $period) {
      id
      name
      phone
      email
      orderStats{
        numOfOrders
        averageAmount
      }
      createdOn
    }
  }  
`

export const UPLOAD_CUSTOMERS = gql`
mutation UploadCustomers($storeId: ID!, $customers: [CustomerData]!) {
  uploadCustomers(storeId: $storeId, customers: $customers) {
    id
    name
    phone
    email
    orderStats {
      numOfOrders
      averageAmount
    }
    createdOn
  }
}
`

export const DELETE_CUSTOMER = gql`
mutation DeleteCustomer($id: ID!) {
  deleteCustomer(id: $id) {
    id
  }
}
`

export const SUMMARY = gql`
query GetSummary($storeId: ID) {
  customersSummary(storeId: $storeId) {
    total,
    verified,
    unverified,
    withConsent,
    withoutConsent,
  }
}
`

export const EXPERIENCE_SUMMARY = gql`
query GetSummary($period: PeriodFilter!, $storeId: ID) {
  customerExperienceSummary(period: $period, storeId: $storeId) {
    avgRating
    avgAcceptanceTime
    avgPreparationTime
    avgDeliveryTime
    cancellationRate
  }
}
`

export const REVIEWS = gql`
query getReviews(
  $limit: Int!
  $offset: Int!
  $storeId: ID
  $period: PeriodFilter
) {
  reviews(limit: $limit, offset: $offset, storeId: $storeId, period: $period) {
    id
    rating
    comment
    orderNo
    customer{
      name
      phone
    }
    createdOn
  }
}  
`

export const LOYALTY_PROGRAM = gql`
query GetLoyaltyProgram {
  loyaltyProgram {
    id
    activeStatus
    amountSpent
    pointsEarned
    loyaltyRewards {
      id
      title
      discountType
      points
      amount
    }
  }
}
`

export const CONFIGURE_LOYALTY_PROGRAM = gql`
mutation ConfigureLoyaltyProgram(
  $amountSpent: Int
  $pointsEarned: Int
  $awardExistingCustomers: Boolean
  $activeStatus: Boolean
) {
  configureLoyaltyProgram(
    amountSpent: $amountSpent
    pointsEarned: $pointsEarned
    awardExistingCustomers: $awardExistingCustomers
    activeStatus: $activeStatus
  ) {
    id
    activeStatus
    amountSpent
    pointsEarned
    loyaltyRewards {
      id
      title
      discountType
      points
      amount
    }
  }
}
`

export const CREATE_UPDATE_REWARD = gql`
mutation CreateUpdateReward(
  $id: ID
  $programId: ID!
  $title: String!
  $discountType: String!
  $points: Int
  $amount: Float!
) {
  createUpdateLoyaltyReward(
    id: $id
    programId: $programId
    title: $title
    discountType: $discountType
    points: $points
    amount: $amount
  ) {
    id
    title
    discountType
    points
    amount
  }
}
`

export const DELETE_REWARD = gql`
mutation DeleteReward($id: ID!) {
  deleteLoyaltyReward(id: $id) {
    id
    title
    discountType
    points
    amount
  }
}
`
