import React, { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Box, Button, Chip, TextField, Typography,
} from '@mui/material'
import {
  Cancel, Check, CheckCircle, Edit,
} from '@mui/icons-material'
import toast from 'react-hot-toast'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import '../styles/stores.css'
import LoyaltyStepper from '../components/customers/LoyaltyStepper'
import LoyaltyDialog from '../components/customers/LoyaltyDialog'
import RewardsTable from './RewardsTable'
import { CONFIGURE_LOYALTY_PROGRAM, LOYALTY_PROGRAM } from '../graphql/customers'

export default function LoyaltyProgramPage() {
  const [program, setProgram] = useState(null)
  const [error, setError] = useState(null)
  const [settingsOpen, toggleSettings] = useState(false)

  const [configureReq, { loading: deactivating }] = useMutation(CONFIGURE_LOYALTY_PROGRAM, {
    onError: () => { toast.error('Error deactivating loyalty program.') },
    onCompleted: (data) => {
      toast.success('Loyalty program deactivated successfully')
      setProgram(data.configureLoyaltyProgram)
    },
  })

  const deactivateProgram = () => {
    configureReq({
      variables: {
        activeStatus: false,
      },
    })
  }

  const handleResponse = (data) => {
    setError(null)
    setProgram(data.loyaltyProgram)
  }
  const [getLoyaltyProgram, { loading }] = useLazyQuery(LOYALTY_PROGRAM, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })

  useEffect(() => {
    getLoyaltyProgram()
  }, [])

  return (
    <div className="page-content">
      <div className="page-tittle no-margin">Loyalty Program</div>
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getLoyaltyProgram} />
      { settingsOpen ? <LoyaltyDialog config={program} close={() => { toggleSettings(false) }} update={(p) => { setProgram(p) }} /> : null}
      { !loading && !error
        ? (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ width: '100%', border: '1px solid #ddd', p: 2 }}>
              <LoyaltyStepper />
              <Box sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2,
              }}
              >
                <Chip
                  label={program?.activeStatus ? 'Active' : 'Inactive'}
                  icon={program?.activeStatus ? <CheckCircle /> : <Cancel />}
                  color={program?.activeStatus ? 'success' : 'default'}
                  sx={{ fontSize: '1rem', p: 2.5, borderRadius: 20 }}
                />
                { !program?.activeStatus ? <Button variant="outlined" size="small" sx={{ ml: 1 }} onClick={() => { toggleSettings(true) }} endIcon={<Check />}>Activate</Button>
                  : <Button variant="outlined" size="small" sx={{ ml: 1 }} disabled={deactivating} onClick={deactivateProgram}>Deactivate</Button> }
              </Box>
            </Box>
            {
            program ? (
              <>
                <Box sx={{
                  width: '100%', border: '1px solid #ddd', p: 1, mt: 2,
                }}
                >
                  <Typography sx={{ mb: 1.5 }}>How Customers Earn Points</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <TextField size="small" value={program.amountSpent} label="Amount Spent" sx={{ flex: 0.43 }} />
                    <Typography>=</Typography>
                    <TextField size="small" value={program.pointsEarned} label="Points Earned" sx={{ flex: 0.43 }} />
                    <Button startIcon={<Edit />} onClick={() => { toggleSettings(true) }} sx={{ flex: 0.1, textTransform: 'capitalize' }}><u>Edit</u></Button>
                  </Box>
                </Box>
                <RewardsTable programId={program.id} rewards={program.loyaltyRewards || []} />
              </>
            ) : null
           }
          </Box>
        ) : null}
    </div>
  )
}
