/* eslint-disable react/no-unescaped-entities */
import { Save, Settings } from '@mui/icons-material'
import {
  Box, IconButton, Typography, DialogActions, TextField, Button,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { useState } from 'react'
import ResponsiveModal from '../layout/ResponsiveModal'
import { UPDATE_MERCHANT } from '../../graphql/merchant'
import Logo from '../../img/paystack.png'

function Actions({ loading, close }) {
  return (
    <DialogActions sx={{
      display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
    }}
    >
      <Button onClick={close} sx={{ mr: 2 }}>Cancel</Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<Save />}
        variant="contained"
        form="save-paystack-integration-form"
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>
    </DialogActions>
  )
}

function PaystackConfigurations({ merchant, update, close }) {
  const [paystackPublicKey, setPublickKey] = useState(merchant?.paystackPublicKey || '')
  const [paystackSecretKey, setSecretKey] = useState(merchant?.paystackSecretKey || '')

  const [updateReq, { loading }] = useMutation(UPDATE_MERCHANT, {
    onError: () => { toast.error('Error updating mpesa integration.') },
    onCompleted: (data) => {
      update(data.updateMerchantDetails)
      toast.success('Mpesa integration updated successfully.')
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    updateReq({
      variables: {
        paystackPublicKey,
        paystackSecretKey,
      },
    })
  }
  return (
    <ResponsiveModal
      title="Paystack Configuration"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions loading={loading} close={close} />}
    >
      <Box component="form" id="save-paystack-integration-form" onSubmit={onSave}>
        <TextField fullWidth label="Paystack Public Key" value={paystackPublicKey} size="small" onChange={(e) => { setPublickKey(e.target.value) }} />
        <TextField type="password" fullWidth sx={{ mt: 2 }} label="Paystack Secret Key" value={paystackSecretKey} size="small" onChange={(e) => { setSecretKey(e.target.value) }} />
      </Box>
    </ResponsiveModal>
  )
}

export default function PaystackItegration({ merchant, updateMerchant }) {
  const [configOpen, toggleConfig] = useState(false)
  const { paystackPublicKey, paystackSecretKey } = merchant
  const isConnected = paystackPublicKey && paystackSecretKey
  return (
    <Box sx={{
      background: 'linear-gradient(90deg, #020024 0%, #4c0979 10%, #4c0979 100%)',
      width: '500px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 5,
    }}
    >
      { configOpen ? <PaystackConfigurations merchant={merchant} close={() => { toggleConfig(false) }} update={updateMerchant} /> : null }
      <Box sx={{
        display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', py: 2, flex: 1,
      }}
      >
        <Box sx={{ width: '200px', height: '50px', mx: 2 }}>
          <img src={Logo} alt="leta logo" style={{ width: '100%', height: '100%' }} />
        </Box>
        <Box>
          <Typography variant="h5" color="#fff">Paystack API</Typography>
          <Typography variant="caption" color="#fff">Paystack integration enables you to collect card payments from Sokolink direct to your Paystack account. Customers who select card as their payment method will be redirected to your Paystack payment link to pay for their order. confirm Paystack's pricing before proceeding with the integration.</Typography>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex', justifyContent: 'space-around', alignItems: 'center', py: 2,
      }}
      >
        <Box sx={{
          backgroundColor: '#fff', display: 'flex', alignItems: 'center', p: 1, borderRadius: 5,
        }}
        >
          <Box
            component="span"
            sx={{
              display: 'block',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: isConnected ? 'success.main' : 'error.main',
              mr: 1,
            }}
          />
          <Typography>{isConnected ? 'Connected' : 'Not Connected'}</Typography>
        </Box>
        <IconButton onClick={() => { toggleConfig(true) }}>
          <Settings sx={{ color: '#fff', fontSize: '35px' }} />
        </IconButton>
      </Box>
    </Box>
  )
}
