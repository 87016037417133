/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from 'react'
import {
  Box, Button, Checkbox, DialogActions, FormControlLabel, TextField,
  Typography,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import ResponsiveModal from '../layout/ResponsiveModal'
import { CONFIGURE_LOYALTY_PROGRAM } from '../../graphql/customers'

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mr: 2 }}
        form="loyalty-settings-form"
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>
    </DialogActions>
  )
}

export default function LoyaltyDialog({ config, update, close }) {
  const [amountSpent, setAmountSpent] = useState(config?.amountSpent || '')
  const [pointsEarned, setPointsEarned] = useState(config?.pointsEarned || '')
  const [awardExistingCustomers, toggleAwardExistingCustomers] = useState(false)

  const [configureReq, { loading }] = useMutation(CONFIGURE_LOYALTY_PROGRAM, {
    onError: () => { toast.error('Error configuring loyalty program.') },
    onCompleted: (data) => {
      toast.success('Loyalty program configured successfully')
      update(data.configureLoyaltyProgram)
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    configureReq({
      variables: {
        activeStatus: true,
        amountSpent: parseInt(amountSpent, 10),
        pointsEarned: parseInt(pointsEarned, 10),
        awardExistingCustomers,
      },
    })
  }

  return (
    <ResponsiveModal
      title="Loyalty Program Settings"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions onSave={onSave} loading={loading} close={close} />}
    >
      <Box component="form" id="loyalty-settings-form" onSubmit={onSave}>
        <Typography sx={{ mb: 2 }}>Customers will earn points for the order amount spent based on this configuration.</Typography>
        <TextField type="number" size="small" required value={amountSpent} onChange={(e) => { setAmountSpent(e.target.value) }} label="Amount Spent" variant="outlined" fullWidth />
        <TextField type="number" size="small" required value={pointsEarned} onChange={(e) => { setPointsEarned(e.target.value) }} label="Points Earned" variant="outlined" fullWidth sx={{ mt: 2 }} />
        { !config ? <FormControlLabel sx={{ mt: 2 }} control={<Checkbox checked={awardExistingCustomers} onChange={() => { toggleAwardExistingCustomers(!awardExistingCustomers) }} />} label="Award points to already existing customers" /> : null }
      </Box>
    </ResponsiveModal>
  )
}
