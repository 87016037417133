import { Add, Delete, Edit } from '@mui/icons-material'
import {
  Box, Button, TextField, Typography,
} from '@mui/material'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import RewardDialog from '../components/customers/RewardDialog'
import { formatMoney } from '../utils/formatter'
import DeleteDialog from '../components/shared/DeleteDialog'
import { DELETE_REWARD } from '../graphql/customers'

function Reward({ programId, reward: initialReward, onDelete }) {
  const [reward, setReward] = useState(initialReward)
  const [editOpen, toggleEdit] = useState(false)
  const [deleteOpen, toggleDelete] = useState(false)
  const {
    id, title, discountType, points, amount,
  } = reward
  const discountAmount = discountType === 'percentage' ? `${amount}%` : `${formatMoney(amount)}`

  const [deleteReq, { loading: deleting }] = useMutation(DELETE_REWARD, {
    onError: () => { toast.error('Error removing reward.') },
    onCompleted: () => { toast.success('Reward successfully'); onDelete(id); toggleDelete(false) },
  })
  const handleDelete = () => {
    deleteReq({
      variables: {
        id,
      },
    })
  }
  return (
    <Box>
      { editOpen ? <RewardDialog reward={reward} programId={programId} update={(r) => { setReward(r) }} close={() => { toggleEdit(false) }} /> : null }
      { deleteOpen ? <DeleteDialog visible msg={`Remove ${title} Reward`} loading={deleting} onDelete={handleDelete} close={() => { toggleDelete(false) }} /> : null }
      <Button sx={{ textTransform: 'inherit', mb: 1.5 }} onClick={() => { toggleEdit(true) }} endIcon={<Edit fontSize="2" />}><u>{title}</u></Button>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <TextField size="small" value={points} label="Points to Redeem" sx={{ flex: 0.43 }} />
        <Typography>=</Typography>
        <TextField size="small" value={discountAmount} label="Reward Amount" sx={{ flex: 0.43 }} />
        <Button startIcon={<Delete />} onClick={() => { toggleDelete(true) }} sx={{ flex: 0.1, color: 'red', textTransform: 'capitalize' }}>
          Delete
        </Button>
      </Box>
    </Box>
  )
}

export default function RewardsTable({ programId, rewards }) {
  const [addOpen, toggleAdd] = useState(false)
  const [allRewards, setRewards] = useState(rewards)

  const deleteReward = (id) => {
    setRewards(allRewards.filter((r) => (r.id !== id)))
  }
  return (
    <Box sx={{
      width: '100%', border: '1px solid #ddd', p: 1, mt: 2,
    }}
    >
      { addOpen ? <RewardDialog programId={programId} update={(r) => { setRewards([...allRewards, r]) }} close={() => { toggleAdd(false) }} /> : null }
      <Box sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
      }}
      >
        <Typography>Rewards that your customers get when they redeem their points</Typography>
        <Button variant="outlined" size="small" sx={{ minWidth: '85px' }} startIcon={<Add />} onClick={() => { toggleAdd(true) }}>Reward</Button>
      </Box>
      { allRewards.map((r) => <Reward programId={programId} reward={r} key={r.id} onDelete={(id) => { deleteReward(id) }} />) }
    </Box>
  )
}
