/* eslint-disable react/no-unescaped-entities */
import { Save, Settings } from '@mui/icons-material'
import {
  Box, IconButton, Typography, DialogActions, TextField, Button,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { useState } from 'react'
import ResponsiveModal from '../layout/ResponsiveModal'
import { UPDATE_MERCHANT } from '../../graphql/merchant'
import Logo from '../../img/letalogo.png'

function Actions({ loading, close }) {
  return (
    <DialogActions sx={{
      display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
    }}
    >
      <Button onClick={close} sx={{ mr: 2 }}>Cancel</Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<Save />}
        variant="contained"
        form="save-leta-integration-form"
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>
    </DialogActions>
  )
}
function LetaConfigurations({ merchant, update, close }) {
  const [letaToken, setToken] = useState(merchant?.letaToken || '')

  const [updateReq, { loading }] = useMutation(UPDATE_MERCHANT, {
    onError: () => { toast.error('Error updating leta configuration.') },
    onCompleted: (data) => {
      update(data.updateMerchantDetails)
      toast.success('Leta configuration  updated successfully.')
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    updateReq({
      variables: {
        letaToken: letaToken === merchant?.letaToken ? undefined : letaToken,
      },
    })
  }
  return (
    <ResponsiveModal
      title="Leta API Configuration"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions loading={loading} close={close} />}
    >
      <Box component="form" id="save-leta-integration-form" onSubmit={onSave}>
        <TextField fullWidth type="password" label="Leta Token" value={letaToken} size="small" onChange={(e) => { setToken(e.target.value) }} required />
      </Box>
    </ResponsiveModal>
  )
}

export default function LetaIntegration({ merchant, updateMerchant }) {
  const [configOpen, toggleConfig] = useState(false)
  const { letaToken } = merchant
  const isConnected = !!letaToken
  return (
    <Box sx={{
      background: 'linear-gradient(90deg, #020024 0%, #4c0979 10%, #4c0979 100%)',
      width: '500px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 5,
    }}
    >
      { configOpen ? <LetaConfigurations merchant={merchant} close={() => { toggleConfig(false) }} update={updateMerchant} /> : null }
      <Box sx={{
        display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', py: 2, flex: 1,
      }}
      >
        <Box sx={{ width: '200px', height: '50px', mx: 2 }}>
          <img src={Logo} alt="leta logo" style={{ width: '100%', height: '100%' }} />
        </Box>
        <Box>
          <Typography variant="h5" color="#fff">Leta API</Typography>
          <Typography variant="caption" color="#fff">LETA as a logistics partner will help you get riders and manage your deliveries. When checking out customers will pay the delivery amount as per LETA pricing, upon accepting delivery order Sokolink will create the LETA order automatically with the collected customer details.</Typography>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex', justifyContent: 'space-around', alignItems: 'center', py: 2,
      }}
      >
        <Box sx={{
          backgroundColor: '#fff', display: 'flex', alignItems: 'center', p: 1, borderRadius: 5,
        }}
        >
          <Box
            component="span"
            sx={{
              display: 'block',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: isConnected ? 'success.main' : 'error.main',
              mr: 1,
            }}
          />
          <Typography>{isConnected ? 'Connected' : 'Not Connected'}</Typography>
        </Box>
        <IconButton onClick={() => { toggleConfig(true) }}>
          <Settings sx={{ color: '#fff', fontSize: '35px' }} />
        </IconButton>
      </Box>
    </Box>
  )
}
