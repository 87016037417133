/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from 'react'
import {
  Box, Button, DialogActions, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import ResponsiveModal from '../layout/ResponsiveModal'
import { CREATE_UPDATE_REWARD } from '../../graphql/customers'

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mr: 2 }}
        form="loyalty-reward-form"
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>
    </DialogActions>
  )
}

export default function RewardDialog({
  programId, reward, update, close,
}) {
  const [title, setTitle] = useState(reward?.title || '')
  const [discountType, setDiscountType] = useState(reward?.discountType || 'fixed')
  const [discountAmount, setDiscountAmount] = useState(reward?.amount || '')
  const [pointsEarned, setPointsEarned] = useState(reward?.points || '')

  const [createUpdateReq, { loading }] = useMutation(CREATE_UPDATE_REWARD, {
    onError: () => { toast.error('Error saving loyalty reward.') },
    onCompleted: (data) => {
      toast.success('Reward saved successfully')
      update(data.createUpdateLoyaltyReward)
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    createUpdateReq({
      variables: {
        id: reward?.id,
        programId,
        title,
        discountType,
        amount: parseInt(discountAmount, 10),
        points: parseInt(pointsEarned, 10),
      },
    })
  }

  return (
    <ResponsiveModal
      title="Loyalty Reward"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions onSave={onSave} loading={loading} close={close} />}
    >
      <Box component="form" id="loyalty-reward-form" onSubmit={onSave}>
        <TextField type="text" size="small" required value={title} onChange={(e) => { setTitle(e.target.value) }} label="Title" variant="outlined" fullWidth sx={{ mb: 3 }} />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Discount Type</InputLabel>
          <Select
            value={discountType}
            label="Discount Type"
            onChange={(e) => { setDiscountType(e.target.value) }}
            type="text"
            size="small"
          >
            <MenuItem value="fixed">Fixed Discount</MenuItem>
            <MenuItem value="percentage">Percentage Discount</MenuItem>
          </Select>
        </FormControl>
        <TextField type="number" size="small" required value={discountAmount} onChange={(e) => { setDiscountAmount(e.target.value) }} label={discountType === 'fixed' ? 'Discount Amount' : '% Discount'} variant="outlined" fullWidth sx={{ mb: 2 }} />
        <TextField type="number" size="small" required value={pointsEarned} onChange={(e) => { setPointsEarned(e.target.value) }} label="Points Redeemed" variant="outlined" fullWidth sx={{ mb: 2 }} />
      </Box>
    </ResponsiveModal>
  )
}
