import React, { useEffect, useRef } from 'react'
import GetAppIcon from '@mui/icons-material/GetApp'
import QRCodeStyling from 'qr-code-styling'
import { Box, Button } from '@mui/material'

export default function QrCode({ domain }) {
  const data = domain
  const qrCode = new QRCodeStyling({
    width: 250,
    height: 250,
    dotsOptions: {
      color: '#4267b2',
      type: 'extra-rounded',
    },
    imageOptions: {
      crossOrigin: 'anonymous',
    },
  })
  const onDownloadClick = () => {
    qrCode.download({
      extension: 'png',
    })
  }
  const ref = useRef(null)
  useEffect(() => {
    qrCode.append(ref.current)
  }, [domain])
  useEffect(() => {
    qrCode.update({
      data,
    })
  }, [domain])
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div ref={ref} />
      <Button
        onClick={onDownloadClick}
        variant="outlined"
        size="small"
        sx={{ width: '250px', mb: 1 }}
        startIcon={<GetAppIcon />}
      >
        Download
      </Button>
    </Box>
  )
}
