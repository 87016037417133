import React, { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Box, Switch, Divider,
} from '@mui/material'
import { toast } from 'react-hot-toast'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import '../styles/overview.css'
import { MERCHANT, UPDATE_MERCHANT } from '../graphql/merchant'

import BusinessInfo from '../components/overview/BusinessInfo'
import ContactPerson from '../components/overview/ContactPerson'
import WhatsappIntegration from '../components/overview/WhatsappIntegration'
import LetaIntegration from '../components/overview/LetaIntegrations'
import MpesaItegration from '../components/overview/MpesaIntegration'
import PaystackItegration from '../components/overview/PaystackIntegration'

function BusinessSettings({ merchant, updateMerchant }) {
  const [costBearer, setBearer] = useState(merchant?.transactionCostBearer)
  const [enforceConsent, toggleConsent] = useState(merchant?.enforceConsent)

  const handleErrors = () => {
    toast.error('erro updating settings')
  }

  const handleResponse = (data) => {
    toast.success('settings updated')
    updateMerchant(data.updateMerchantDetails)
  }

  const [updateSettingsReq, { loading }] = useMutation(UPDATE_MERCHANT, { onError: handleErrors, onCompleted: handleResponse })
  const handleCostBearerChange = (e) => {
    updateSettingsReq({
      variables: {
        transactionCostBearer: e.target.value,
      },
    })
    setBearer(e.target.value)
  }
  const handleConsentChange = (e) => {
    updateSettingsReq({
      variables: {
        enforceConsent: e.target.checked,
      },
    })
    toggleConsent(e.target.checked)
  }
  return (
    <div>
      <div className="settings-section">
        <BusinessInfo merchant={merchant} updateMerchant={updateMerchant} />
      </div>
      <div className="settings-section">
        <ContactPerson merchant={merchant} updateMerchant={updateMerchant} />
      </div>
      <div className="settings-section">
        <Box sx={{ padding: 2 }}>
          <RadioGroup
            value={costBearer}
            onChange={handleCostBearerChange}
            sx={{ mb: 1 }}
          >
            <FormControlLabel disabled={loading} value="merchant" control={<Radio />} label="Charge me for the transaction fees" />
            <FormControlLabel disabled={loading} value="customer" control={<Radio />} label="Make customers pay the transaction fees" />
          </RadioGroup>
          <FormControlLabel disabled={loading} control={<Switch checked={enforceConsent} onChange={handleConsentChange} />} labelPlacement="end" label="Enforce consent when collecting user details" />
        </Box>
      </div>
    </div>
  )
}

function Integrations({ merchant, updateMerchant }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        gap: 3,
        '& > *': {
          flex: {
            xs: '1 1 100%',
            md: '1 1 45%',
          },
          display: 'flex',
          justifyContent: 'center',
        },
      }}
    >
      <WhatsappIntegration merchant={merchant} updateMerchant={updateMerchant} />
      <LetaIntegration merchant={merchant} updateMerchant={updateMerchant} />
      <MpesaItegration merchant={merchant} updateMerchant={updateMerchant} />
      <PaystackItegration merchant={merchant} updateMerchant={updateMerchant} />
    </Box>
  )
}

export default function Overview() {
  const [merchant, setMerchant] = useState(null)
  const [error, setError] = useState(null)
  const [activeTab, setActiveTab] = useState('general')

  const updateMerchant = (newMerchant) => {
    setMerchant(newMerchant)
  }

  const handleResponse = (data) => {
    setError(null)
    if (data.merchant) {
      updateMerchant(data.merchant)
    }
  }
  const [getMerchant, { loading }] = useLazyQuery(MERCHANT, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  useEffect(() => {
    getMerchant()
  }, [])

  return (
    <Box sx={{ px: 1 }}>
      <Box>
        <Tabs value={activeTab}>
          <Tab label="General Settings" value="general" onClick={() => { setActiveTab('general') }} />
          <Tab label="Integrations" value="integrations" onClick={() => { setActiveTab('integrations') }} />
        </Tabs>
      </Box>
      <Divider sx={{ mx: -1, mb: 2 }} />
      {!loading && !error && merchant && activeTab === 'general' ? <BusinessSettings merchant={merchant} updateMerchant={updateMerchant} /> : null}
      {!loading && !error && merchant && activeTab === 'integrations' ? <Integrations merchant={merchant} updateMerchant={updateMerchant} /> : null}
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getMerchant} />
    </Box>
  )
}
