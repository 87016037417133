/* eslint-disable react/no-unescaped-entities */
import { Save, Settings } from '@mui/icons-material'
import {
  Box, IconButton, Typography, DialogActions, TextField, Button,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { useState } from 'react'
import ResponsiveModal from '../layout/ResponsiveModal'
import { UPDATE_MERCHANT } from '../../graphql/merchant'
import Logo from '../../img/mpesa.png'

function Actions({ loading, close }) {
  return (
    <DialogActions sx={{
      display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
    }}
    >
      <Button onClick={close} sx={{ mr: 2 }}>Cancel</Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<Save />}
        variant="contained"
        form="save-mpesa-integration-form"
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>
    </DialogActions>
  )
}

function MpesaConfigurations({ merchant, update, close }) {
  const [mpesaTill, setMpesaTill] = useState(merchant?.mpesaTill || '')
  const [mpesaShortCode, setShortCode] = useState(merchant?.mpesaShortCode || '')
  const [mpesaConsumerKey, setConsumerKey] = useState(merchant?.mpesaConsumerKey || '')
  const [mpesaConsumerSecret, setConsumerSecret] = useState(merchant?.mpesaConsumerSecret || '')
  const [mpesaPassKey, setPassKey] = useState(merchant?.mpesaPassKey || '')

  const [updateReq, { loading }] = useMutation(UPDATE_MERCHANT, {
    onError: () => { toast.error('Error updating mpesa integration.') },
    onCompleted: (data) => {
      update(data.updateMerchantDetails)
      toast.success('Mpesa integration updated successfully.')
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    updateReq({
      variables: {
        mpesaTill,
        mpesaShortCode,
        mpesaConsumerKey: mpesaConsumerKey === merchant?.mpesaConsumerKey ? undefined : mpesaConsumerKey,
        mpesaConsumerSecret: mpesaConsumerSecret === merchant?.mpesaConsumerSecret ? undefined : mpesaConsumerSecret,
        mpesaPassKey: mpesaPassKey === merchant?.mpesaPassKey ? undefined : mpesaPassKey,
      },
    })
  }
  return (
    <ResponsiveModal
      title="Mpesa API Configuration"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions loading={loading} close={close} />}
    >
      <Box component="form" id="save-mpesa-integration-form" onSubmit={onSave}>
        <TextField fullWidth type="number" label="Mpesa Till" value={mpesaTill} size="small" onChange={(e) => { setMpesaTill(e.target.value) }} />
        <TextField fullWidth sx={{ mt: 2 }} type="number" label="Business Short Code" value={mpesaShortCode} size="small" onChange={(e) => { setShortCode(e.target.value) }} />
        <TextField fullWidth sx={{ mt: 2 }} type="password" label="Consumer Key" value={mpesaConsumerKey} size="small" onChange={(e) => { setConsumerKey(e.target.value) }} />
        <TextField fullWidth sx={{ mt: 2 }} type="password" label="Consumer Secret" value={mpesaConsumerSecret} size="small" onChange={(e) => { setConsumerSecret(e.target.value) }} />
        <TextField fullWidth sx={{ mt: 2 }} type="password" label="Pass Key" value={mpesaPassKey} size="small" onChange={(e) => { setPassKey(e.target.value) }} />
      </Box>
    </ResponsiveModal>
  )
}

export default function MpesaItegration({ merchant, updateMerchant }) {
  const [configOpen, toggleConfig] = useState(false)
  const {
    mpesaTill, mpesaShortCode, mpesaConsumerKey, mpesaConsumerSecret, mpesaPassKey,
  } = merchant
  const isConnected = mpesaTill && mpesaShortCode && mpesaConsumerKey && mpesaConsumerSecret && mpesaPassKey
  return (
    <Box sx={{
      background: 'linear-gradient(90deg, #020024 0%, #4c0979 10%, #4c0979 100%)',
      width: '500px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 5,
    }}
    >
      { configOpen ? <MpesaConfigurations merchant={merchant} close={() => { toggleConfig(false) }} update={updateMerchant} /> : null }
      <Box sx={{
        display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', py: 2, flex: 1,
      }}
      >
        <Box sx={{ width: '200px', height: '50px', mx: 2 }}>
          <img src={Logo} alt="leta logo" style={{ width: '100%', height: '100%' }} />
        </Box>
        <Box>
          <Typography variant="h5" color="#fff">Daraja API</Typography>
          <Typography variant="caption" color="#fff">Mpesa Daraja API integration helps you receive online payments directly into your Till Number or Paybill. When paying using Mpesa customers will receive a push notification from your business details as registered with Mpesa.</Typography>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex', justifyContent: 'space-around', alignItems: 'center', py: 2,
      }}
      >
        <Box sx={{
          backgroundColor: '#fff', display: 'flex', alignItems: 'center', p: 1, borderRadius: 5,
        }}
        >
          <Box
            component="span"
            sx={{
              display: 'block',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: isConnected ? 'success.main' : 'error.main',
              mr: 1,
            }}
          />
          <Typography>{isConnected ? 'Connected' : 'Not Connected'}</Typography>
        </Box>
        <IconButton onClick={() => { toggleConfig(true) }}>
          <Settings sx={{ color: '#fff', fontSize: '35px' }} />
        </IconButton>
      </Box>
    </Box>
  )
}
