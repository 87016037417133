import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { Howl } from 'howler'
import { connect } from 'react-redux'
import { useSubscription } from '@apollo/client'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import HomeIcon from '@mui/icons-material/Home'
import InventoryIcon from '@mui/icons-material/Inventory'
import DescriptionIcon from '@mui/icons-material/Description'
import PeopleIcon from '@mui/icons-material/People'
import PlaceIcon from '@mui/icons-material/Place'
import LayersIcon from '@mui/icons-material/Layers'
import { Campaign, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import PaymentIcon from '@mui/icons-material/Payment'
import PaidIcon from '@mui/icons-material/Paid'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import NotificationsIcon from '@mui/icons-material/Notifications'
import LogoutIcon from '@mui/icons-material/Logout'
import StorefrontIcon from '@mui/icons-material/Storefront'
import SettingsIcon from '@mui/icons-material/Settings'
import { Menu, MenuItem, Tooltip } from '@mui/material'
import { onMessageListener } from '../../services/firebasepush'
import { setMerchant } from '../../actions/merchantActions'
import { logoutUser } from '../../actions/userActions'
import './styles/AppContainer.css'
import Logo from '../../img/whitelogo.png'
import { NEW_ORDER } from '../../graphql/orders'
import BellSound from '../../audio/bell.mp3'

const IconsMap = {
  Home: <HomeIcon />,
  Catalogues: <InventoryIcon />,
  Orders: <DescriptionIcon />,
  Customers: <PeopleIcon />,
  Promotions: <Campaign />,
  Storefront: <StorefrontIcon />,
  Stores: <PlaceIcon />,
  Payments: <PaymentIcon />,
  Settlements: <PaidIcon />,
  Wallet: <AccountBalanceWalletIcon />,
  Staff: <AdminPanelSettingsIcon />,
  Settings: <SettingsIcon />,
  'Sign Out': <LogoutIcon />,
  Overview: <HomeIcon />,
  Businesses: <PlaceIcon />,
  'Floor Map': <LayersIcon />,

}

const NavLinkMap = {
  Home: '/business/overview',
  Catalogues: '/business/catalogues',
  Orders: '/business/orders',
  Customers: '/business/customers/records',
  CustomerExperience: '/business/customers/experience',
  LoyaltyProgram: '/business/customers/loyalty',
  Coupons: '/business/coupons',
  Whatsapp: '/business/whatsapp/campaigns',
  Sms: '/business/sms',
  Storefront: '/business/storefront',
  Stores: '/business/stores',
  Payments: '/business/payments',
  Settlements: '/business/settlements',
  Wallet: '/business/wallet',
  Staff: '/business/users',
  Settings: '/business/settings',
  'Sign Out': <LogoutIcon />,
  Overview: '/mall/overview',
  Businesses: '/mall/businesses',
  'Floor Map': '/mall/floor',

}

function NotifBtn({ notifs }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
      >
        {notifs.length ? <div id="notif-value">{notifs.length}</div> : null}
        <Tooltip title="Notifications">
          <NotificationsIcon style={{ color: '#fff', fontSize: '25' }} />
        </Tooltip>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          No New Notifications
        </MenuItem>
      </Menu>
    </>
  )
}

function AccountBtn({ logout }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
      >
        <Tooltip title="Account">
          <AccountCircleIcon style={{ color: '#fff', fontSize: '45' }} />
        </Tooltip>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <NavLink className="nav-link" to="/business/account">
          <MenuItem>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            Account Settings
          </MenuItem>
        </NavLink>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

const drawerWidth = '210px'

function AppContainer({
  window, children, userInfo, merchantInfo, logoutUser: logoutUserAction,
}) {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [webOpen, setWebOpen] = useState(true)
  const [notifs, setNotifs] = useState([])
  const [promotionOpen, togglePromotion] = useState(false)
  const [customersOpen, toggleCustomers] = useState(false)

  const handleNewOrder = ({ subscriptionData }) => {
    const { newOrder } = subscriptionData.data
    if (newOrder) {
      const sound = new Howl({
        src: [BellSound],
        volume: 1,
      })
      const info = `New ${newOrder.orderType} order.`.toLocaleUpperCase()
      const playId = sound.play()
      sound.loop(true, playId)
      toast.success(info, { icon: '🔊🗣', duration: 20000 })
      setTimeout(() => {
        sound.stop(playId)
      }, 20000)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const { data, loading: subLoading, error: subError } = useSubscription(NEW_ORDER, {
    shouldResubscribe: true,
    variables: {
      merchantId: userInfo?.merchantId,
      storeId: userInfo?.storeId,
    },
    onSubscriptionData: handleNewOrder,
  })

  onMessageListener()
    .then((payload) => {
      const { title, body } = payload.data
      setNotifs([...notifs, payload.data])
      toast.success(`${title}; ${body}`)
    })
    .catch((err) => {
      toast.error(JSON.stringify(err))
    })

  const drawerToggleMobile = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawerToggleWeb = () => {
    setWebOpen(!webOpen)
  }

  const closeMobileDrawer = () => {
    setMobileOpen(false)
  }

  const getRoleBasedTopMenu = (role) => {
    if (merchantInfo.category === 'Mall') {
      return ['Overview', 'Floor Map', 'Businesses']
    }
    if (role === 'owner' || role === 'admin') return ['Home', 'Catalogues', 'Orders', 'Payments', 'Customers', 'Promotions']
    if (role === 'operator') return ['Home', 'Orders', 'Payments', 'Customers']
    return []
  }

  const getRoleBasedBottomMenu = (role) => {
    if (merchantInfo.category === 'Mall') {
      return ['Storefront', 'Staff', 'Settings']
    }
    if (role === 'owner') return ['Storefront', 'Stores', 'Settlements', 'Wallet', 'Staff', 'Settings']
    if (role === 'admin') return ['Storefront', 'Stores', 'Staff', 'Settings']
    return []
  }

  const drawer = (
    <div id="drawer">
      <div className="nav-links">
        <Divider />
        <List>
          {getRoleBasedTopMenu(userInfo?.role).map((text) => {
            if (text === 'Customers') {
              return (
                <div key={text}>
                  <ListItem key="Customers" disablePadding>
                    <ListItemButton onClick={() => toggleCustomers(!customersOpen)}>
                      <ListItemIcon sx={{ minWidth: '40px' }}>
                        {IconsMap.Customers}
                      </ListItemIcon>
                      <ListItemText primary="Customers" />
                      { customersOpen ? <KeyboardArrowUp sx={{ ml: 11 }} /> : <KeyboardArrowDown sx={{ ml: 11 }} /> }
                    </ListItemButton>
                  </ListItem>
                  {customersOpen ? (
                    <NavLink key="customer-records" className="nav-link" to={NavLinkMap.CustomerExperience} onClick={closeMobileDrawer}>
                      <ListItemButton sx={{ m: 0, p: 0, pl: 7 }}>
                        <ListItemText primaryTypographyProps={{ fontSize: 15 }} primary="Customer Feedback" />
                      </ListItemButton>
                    </NavLink>
                  ) : null}
                  {customersOpen ? (
                    <NavLink key="customer-experience" className="nav-link" to={NavLinkMap.Customers} onClick={closeMobileDrawer}>
                      <ListItemButton sx={{ m: 0, p: 0, pl: 7 }}>
                        <ListItemText primaryTypographyProps={{ fontSize: 15 }} primary="Customer Records" />
                      </ListItemButton>
                    </NavLink>
                  ) : null}
                  {customersOpen ? (
                    <NavLink key="loyalty-program" className="nav-link" to={NavLinkMap.LoyaltyProgram} onClick={closeMobileDrawer}>
                      <ListItemButton sx={{ m: 0, p: 0, pl: 7 }}>
                        <ListItemText primaryTypographyProps={{ fontSize: 15 }} primary="Loyalty Program" />
                      </ListItemButton>
                    </NavLink>
                  ) : null}
                </div>
              )
            }
            if (text === 'Promotions') {
              return (
                <div key={text}>
                  <ListItem key="Promotions" disablePadding>
                    <ListItemButton onClick={() => togglePromotion(!promotionOpen)}>
                      <ListItemIcon sx={{ minWidth: '40px' }}>
                        {IconsMap.Promotions}
                      </ListItemIcon>
                      <ListItemText primary="Promotions" />
                      { promotionOpen ? <KeyboardArrowUp sx={{ ml: 11 }} /> : <KeyboardArrowDown sx={{ ml: 11 }} /> }
                    </ListItemButton>
                  </ListItem>
                  {promotionOpen ? (
                    <NavLink key="discounts" className="nav-link" to={NavLinkMap.Coupons} onClick={closeMobileDrawer}>
                      <ListItemButton sx={{ m: 0, p: 0, pl: 7 }}>
                        <ListItemText primaryTypographyProps={{ fontSize: 15 }} primary="Discount Coupons" />
                      </ListItemButton>
                    </NavLink>
                  ) : null}
                  {promotionOpen ? (
                    <NavLink key="whatsapp" className="nav-link" to={NavLinkMap.Whatsapp} onClick={closeMobileDrawer}>
                      <ListItemButton sx={{ m: 0, p: 0, pl: 7 }}>
                        <ListItemText primaryTypographyProps={{ fontSize: 15 }} primary="Whatsapp Marketing" />
                      </ListItemButton>
                    </NavLink>
                  ) : null}
                  {promotionOpen ? (
                    <NavLink key="sms" className="nav-link" to={NavLinkMap.Sms} onClick={closeMobileDrawer}>
                      <ListItemButton sx={{ m: 0, p: 0, pl: 7 }}>
                        <ListItemText primaryTypographyProps={{ fontSize: 15 }} primary="Sms Marketing" />
                      </ListItemButton>
                    </NavLink>
                  ) : null}
                </div>
              )
            }
            return (
              <NavLink key={text} className="nav-link" to={NavLinkMap[text]} onClick={closeMobileDrawer}>
                <ListItem key={text} disablePadding>
                  <ListItemButton sx={{ py: 0.8 }}>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      {IconsMap[text]}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            )
          })}
        </List>
        <Divider />
        <List>
          {getRoleBasedBottomMenu(userInfo?.role).map((text) => (
            <NavLink key={text} className="nav-link" to={NavLinkMap[text]} onClick={closeMobileDrawer}>
              <ListItem key={text} disablePadding>
                <ListItemButton sx={{ py: 0.8 }}>
                  <ListItemIcon sx={{ minWidth: '40px' }}>
                    {IconsMap[text]}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
        </List>
      </div>
      <div className="merchant-info">
        <Box sx={{ display: 'flex', p: '5px', alignItems: 'center' }}>
          <div className="merchant-logo">
            <img src={merchantInfo?.logo} alt="merchant logo" />
          </div>
          <Box sx={{ ml: 1 }}>
            <div className="b-name"><strong>{merchantInfo?.businessName}</strong></div>
            <div className="m-id">{`Merchant ID: 138${merchantInfo?.id}`}</div>
          </Box>
        </Box>
      </div>
    </div>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <div className="app-container">
      <AppBar
        position="fixed"
        sx={{
          background: '#0a82f3',
          width: '100%',
        }}
      >
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={drawerToggleMobile}
              sx={{ mr: 2, display: { xs: 'block', sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              color="inherit"
              edge="start"
              onClick={drawerToggleWeb}
              sx={{ mr: 2, display: { xs: 'none', sm: 'block' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <img src={Logo} alt="sokolink logo" className="header-logo" />
            </Box>
          </Box>
          <div className="custom-toolbar">
            <div className="toolbar-holder">
              <div className="notif-holder">
                <NotifBtn notifs={notifs} />
              </div>
              <AccountBtn logout={logoutUserAction} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div className="app">
        <>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={drawerToggleMobile}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            <Toolbar sx={{ background: '#0a82f3' }}>
              <img src={Logo} alt="sokolink logo" className="header-logo" />
            </Toolbar>
            {drawer}
          </Drawer>
          <Drawer
            variant="persistent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                mt: '64px',
                height: 'calc(100vh - 64px)',
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            open={webOpen}
          >
            {drawer}
          </Drawer>
        </>
        <Box className="main" sx={{ pl: { xs: 'none', sm: webOpen ? drawerWidth : 'none' } }}>
          <Toolbar />
          {children}
        </Box>
      </div>
    </div>
  )
}

AppContainer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
//   window: PropTypes.func,
}

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  merchantInfo: state.merchant.merchantInfo,
})
export default connect(mapStateToProps, { setMerchant, logoutUser })(AppContainer)
